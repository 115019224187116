'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'far';
var iconName = 'wifi-slash';
var width = 640;
var height = 512;
var aliases = [];
var unicode = 'f6ac';
var svgPathData = 'M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-267-209.3c47.2 8.4 89.8 30.2 123.8 61.4c9.8 9 24.9 8.3 33.9-1.5s8.3-24.9-1.5-33.9C467.4 237.5 397.1 208 320 208c-7.2 0-14.3 .3-21.4 .8L169.4 107.5C216.2 89.7 267 80 320 80c107.4 0 205.4 39.9 280.1 105.7c9.9 8.8 25.1 7.8 33.9-2.2s7.8-25.1-2.2-33.9C548.7 76.4 439.5 32 320 32c-69.1 0-134.7 14.9-193.9 41.5L38.8 5.1zM76.9 156.6L37.7 125.7c-10.2 7.6-20 15.6-29.5 23.9c-9.9 8.8-10.9 23.9-2.2 33.9s23.9 10.9 33.9 2.2c11.8-10.4 24.1-20.1 37-29.1zM226.3 274.3L184 241c-23.4 12.1-44.9 27.2-64.1 44.8c-9.8 9-10.4 24.1-1.5 33.9s24.1 10.4 33.9 1.5c21.5-19.7 46.5-35.7 73.9-46.9zM376 424a56 56 0 1 0 -112 0 56 56 0 1 0 112 0z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [
    width,
    height,
    aliases,
    unicode,
    svgPathData
  ]};

exports.faWifiSlash = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;